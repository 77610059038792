import React from "react";

const Footer = () => {
  const anoAtual = new Date().getFullYear();
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © {anoAtual}{" "}
              <a className="fw-600" href="/">
                ALS Softwares
              </a>
              . Todos direitos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
