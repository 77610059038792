import React from "react";

const Services = () => {
  // services details
  const services = [
    
    {
      name: "Sites e Landing Pages",
      desc: "Desenvolvemos softwares inovadores, moldando soluções digitais para atender às necessidades exclusivas dos nossos clientes.",
      icon: "fas fa-code",
    },
    {
      name: "Sistemas Completos",
      desc: "Construímos sistemas personalizados, proporcionando soluções digitais sob medida para impulsionar o sucesso dos nossos clientes.",
      icon: "fas fa-chart-area",
    },
    {
      name: "Lojas Virtuais",
      desc: "Transformamos ideias em oportunidades de venda online, criando lojas virtuais eficientes e atraentes para impulsionar o crescimento dos nossos clientes.",
      icon: "fas fa-store",
    },
    {
      name: "UI/UX Design",
      desc: "Priorizamos UI/UX Design para garantir uma experiência online intuitiva e visualmente atrativa em nosso site.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "SEO Marketing",
      desc: "O SEO Marketing é crucial para aumentar a visibilidade online, atrair tráfego qualificado e melhorar a posição de um site nos resultados de pesquisa.",
      icon: "fas fa-bullhorn",
    },
   
    {
      name: "Design Moderno",
      desc: "Elevamos sua presença online com designs cativantes. Com interfaces intuitivas e modernas para destacar sua marca no mundo digital.",
      icon: "fas fa-desktop",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">O que fazemos?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Como podemos te ajudar em seu próximo projeto
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
