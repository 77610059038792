import React, { useState } from "react";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const [imagesLoaded, setimagesLoaded] = useState(0);

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    // {
    //   title: "Julia Ferrareto Advocacia e Consultoria",
    //   type: types.IMAGE,
    //   url: "https://jferraretoadvocacia.com.br/",
    //   thumbImage: "images/projects/jferrareto.png",
    // },
    {
      title: "Athus Comunicação Visual",
      type: types.IMAGE,
      url: "http://athusvisual.com.br/",
      thumbImage: "images/projects/athus.png",
    },
  ];

  const navigateTo = (value) => {
    window.open(value, '_blank');
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfólio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Nossos últimos projetos
          </h2>
          {/* Heading end*/}

          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item"
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            navigateTo(project.url);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                                <i className="fas fa-link"></i>
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-center text-4 mt-2">{project.title}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
