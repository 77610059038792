import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!forms.name || forms.name.trim() === "") {
      newErrors.name = "O campo Nome é obrigatório";
    }

    if (forms.name.length <= 5) {
      newErrors.name = "Insira seu nome completo";
    }

    if (!forms.phone) {
      newErrors.phone = "Por favor, insira um número de telefone válido";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!forms.email || !emailRegex.test(forms.email)) {
      newErrors.email = "Por favor, insira um endereço de e-mail válido";
    }

    if (!forms.message || forms.message.trim() === "") {
      newErrors.message = "O campo Mensagem é obrigatório";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length > 0 ? newErrors : null;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (!validationErrors) {
      setIsLoading(true);
      const mailOptions = {
        to: "andre@alssoftwares.com.br",
        subject: `Mensagem do Site`,
        replyTo: forms.email,
        from: 'als',
        html: `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
          </head>
          <body>
            <p><strong>Nome:</strong> ${forms.name}</p>
            <p><strong>Telefone:</strong> ${forms.phone}</p>
            <p><strong>E-mail:</strong> ${forms.email}</p>
            <p><strong>Mensagem:</strong> ${forms.message}</p>
          </body>
          </html>
        `,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DOMAIN_URL}/send-email`,
          mailOptions
        );

        if (response.data.success) {
          setForms({
            name: "",
            email: "",
            phone: "",
            message: "",
          });

          toast.success("Sua mensagem foi enviada!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Ocorreu um erro ao enviar sua mensagem!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error("Ocorreu um erro ao enviar sua mensagem!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Entre em contato</h2>
            <p className="text-5 mb-5">
              Por favor, compartilhe detalhes para que possamos otimizar nossa
              primeira interação e tirar o máximo proveito deste início
              promissor.
            </p>
            <h3 className="text-5 fw-600">Atendemos em:</h3>
            <address className="text-4">
              São José do Rio Preto e Região.
            </address>
            <h3 className="text-5 fw-600">Telefone:</h3>
            <a
              href="https://api.whatsapp.com/send?phone=5517996156384&text=Ol%C3%A1,%20encontrei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20discutir%20algumas%20informa%C3%A7%C3%B5es."
              className="text-4 text-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              (17) 99615-6384
            </a>
            <h3 className="text-5 fw-600 mt-3">E-mail:</h3>
            <a
              href="mailto:andre@alssoftwares.com.br?subject=Dúvida ALS Softwares"
              className="text-4 text-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              andre@alssoftwares.com.br
            </a>
            <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
              <li className="social-icons-linkedin">
                <Tooltip text="Linkedin" placement="top">
                  <a
                    href="https://www.linkedin.com/in/andre-lbsilva/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                  <a
                    href="https://www.instagram.com/als.softwares/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Ficou com alguma dúvida?
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              onSubmit={(e) => submitHandler(e)}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="name">
                    Nome completo:
                  </label>
                  <input
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    value={forms.name}
                    id="name"
                    name="name"
                    type="text"
                    className="form-control py-1"
                    required
                  />
                  {errors.name && (
                    <div className="text-red-500 mt-2 text-sm">
                      {errors.name}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="phone">
                    Telefone:
                  </label>
                  <input
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    value={forms.phone}
                    type="phone"
                    name="phone"
                    id="phone"
                    className="form-control py-1"
                    required
                  />
                  {errors.phone && (
                    <div className="text-red-500 mt-2 text-sm">
                      {errors.phone}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="email">
                    E-mail:
                  </label>
                  <input
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    value={forms.email}
                    id="email"
                    name="email"
                    type="email"
                    className="form-control py-1"
                    required
                  />
                  {errors.email && (
                    <div className="text-red-500 mt-2 text-sm">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="form-message">
                    Mensagem:
                  </label>
                  <textarea
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    id="form-message"
                    name="message"
                    className="form-control py-1"
                    rows={4}
                    required
                    value={forms.message}
                    style={{
                      resize: "none",
                    }}
                  />
                  {errors.message && (
                    <div className="text-red-500 mt-2 text-sm">
                      {errors.message}
                    </div>
                  )}
                </div>
                <div className="col-12 text-center text-lg-start">
                  <button
                    id="submit-btn"
                    className="btn btn-dark rounded-0"
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                      </>
                    ) : (
                      <>
                        Enviar{" "}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    )}
                  </button>
                </div>
                <ToastContainer />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
