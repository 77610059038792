import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Sobre</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Nos conheça melhor
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Somos a{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                ALS Softwares
              </span>
            </h2>
            <p className="text-5" style={{textAlign: 'justify'}}>
              Uma equipe apaixonada de desenvolvedores dedicados a criar
              soluções de software robustas e eficazes para atender às
              necessidades do seu negócio. Nosso compromisso é fornecer
              tecnologia acessível e eficiente para impulsionar o crescimento e
              a eficiência operacional da sua empresa.<br/> Com anos de experiência,
              nossa equipe possui o conhecimento e a experiência necessários
              para transformar ideias em realidade. Valorizamos parcerias
              colaborativas e buscamos construir relacionamentos duradouros com
              nossos clientes, baseados na confiança e na transparência. Estamos
              comprometidos em fornecer soluções que evoluam com o seu negócio.
              Nossa visão de longo prazo significa que estamos sempre pensando
              no futuro.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  6
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                anos de <span className="fw-700">experiência</span> com
                Desenvolvimento Web
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
